.count_notification {
  margin-right: -20%;
  margin-top: -15%;
}
.textVerticalBar {
  width: 0.15rem;
  height: 40px;
}
.searchInput {
  width: 100%;
  justify-content: center;
  border-radius: 30px;
  background-color: #f5f5f5;
}

.searchDialog {
  border-radius: 20px;
  height: 450px;
  z-index: 90;
  box-shadow: 1px 1px 1px 1px #969696;
}

.userProfileImageButton:hover .menuIcon {
  color: white;
}
.userProfileImageButton:hover .userProfileImage {
  border: 2px solid white !important;
}
.userProfileImage {
  border-radius: 50%;
  box-shadow: 0 0 0 0.5px #faece8;
}

.customScrollbar::-webkit-scrollbar-track {
  border-radius: 10px;
}

.customScrollbar::-webkit-scrollbar {
  width: 3px;
  border-radius: 10px;
}

.customScrollbar::-webkit-scrollbar-thumb {
  visibility: hidden;
}

*:hover.customScrollbar::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: linear-gradient(to bottom right, #222160, #5756c5);
  visibility: visible;
}
.searchResult {
  cursor: pointer;
}
.searchResult:hover {
  background-color: honeydew;
}

.lightModeButton:active {
  animation: switchThemeModeLeftward 0.5s ease-in-out;
}
.darkModeButton:active {
  animation: switchThemeModeRightward 0.5s ease-in-out;
}
