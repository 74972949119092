@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overscroll-behavior: none;
  color: black;
}
hr {
  background-color: lightgrey;
  height: 1px;
}
input:focus {
  outline: none;
}
input[type="text"]::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}
input[type="text"]::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}
::-webkit-scrollbar {
  display: none;
}
.customScrollbar {
  scroll-behavior: smooth;
}
.customScrollbar::-webkit-scrollbar {
  display: block;
}
.customScrollbar::-webkit-scrollbar-track {
  border-radius: 10px;
}

.customScrollbar::-webkit-scrollbar {
  width: 4px;
  border-radius: 10px;
}

.customScrollbar::-webkit-scrollbar-thumb {
  visibility: hidden;
  @apply dark:from-white dark:to-white bg-gradient-to-r from-[#222160] to-[#5756c5] !important;
}

*:hover.customScrollbar::-webkit-scrollbar-thumb {
  border-radius: 10px;
  visibility: visible;
}

input:disabled {
  background-color: white;
  opacity: 1;
}

.non-selectable {
  user-select: none;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10+ */
  -moz-user-select: none;
  -webkit-user-drag: none;
}
p {
  font-size: 1rem;
  line-height: 1.5rem;
}
h1 {
  font-size: 1.5rem;
  line-height: 2rem;
}
